var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"towers-row",class:{
    'row-active': _vm.towersGame !== null && _vm.towersGame.state !== 'completed' && _vm.towersGame.revealed.length === _vm.row,
    'row-revealed': _vm.towersGame !== null && _vm.towersGame.revealed[_vm.row] !== undefined
}},_vm._l((_vm.towersGetTilesCount),function(tile){return _c('div',{key:tile,staticClass:"row-tile"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.towersGame !== null && _vm.towersGame.revealed[_vm.row] !== undefined && _vm.towersGame.revealed[_vm.row].tile === (tile - 1) && _vm.towersGame.revealed[_vm.row].row[tile - 1] === 'coin')?_c('div',{staticClass:"tile-coin"},[_c('div',{staticClass:"coin-inner"},[_c('div',{staticClass:"inner-value"}),_c('img',{staticClass:"tile-mine",attrs:{"src":"https://s3-alpha-sig.figma.com/img/976b/1c79/441b768c31fef900d821c7dfde8833c8?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=a5sM9C-skch9f7l5zQsqnMf99RBRIAIg5K9uyw5U4Qjqion2JOlv-PB0g9to8epNswZ5ghI6ZffBkdXXrlED-t3~fzEJ-OtMYgWUwlSj7vVJG7jc5eFtKF-iiJGv6qenIkSMTH8R~HDVskKMTFCR1a123GbpD0JF0FJOq4pBH1c5RPI~qmG2HFKGrqxjNmHlRWc3dqHNO9RtBSjexXvwSRUupg8hjHFPs6uXgmc0TXOX5lftNXZfpu38RgKWhNXlVIFRSQqPbcXTE8OIK6~wei1ZTicgVuX89wiGIfyVkFqV9H5b8CPpuCdmhv5hqubJfvuRGHVp0pOFHk6EkS0A1A__"}})])]):(
                    _vm.towersGame !== null && 
                    (
                        (_vm.towersGame.revealed[_vm.row] !== undefined && _vm.towersGame.revealed[_vm.row].tile === (tile - 1) && _vm.towersGame.revealed[_vm.row].row[tile - 1] === 'lose') ||
                        (_vm.towersGame.state === 'completed' && _vm.towersGame.revealed.length <= _vm.row && _vm.towersGame.deck[_vm.row][tile - 1] === 'lose')
                        
                    ))?_c('div',{staticClass:"coin-inners"},[_c('img',{staticClass:"tile-mine",attrs:{"src":"https://s3-alpha-sig.figma.com/img/f5f0/e6ec/4bb275390c4b2d131b70026271e020f9?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jJ4jziSCdm3zn2dMNQ~kBeUoAq95tMhMV8Drn6MSH4xFygPZ~tK4yn46qiJSbyNlps8R4YykvELePxQLtQxUZB0rBr9Z9Kp84BsWEYg3qdCkD2pkeQam~ld1JNfrDsKJM~mBVtsRvyHluQX5AzDxgJz7qGJV-y9na4aLll-E3igvzgrs9lCgwdxe-0Bx5zo8cbCNjx6k1INF~2bblvgfmp1VnwxU3nL6T39--WU8J1rv3mx-HjGh4oSPIludUq3A5SlvtZ5URtLc42QbNsmnJRRc4pZXSg7LeqEVqq-rRAEMl4P8dovfmLltW361M6gC929l7bUE~TZrhnnh-r3Lbw__"}})]):_c('button',{staticClass:"button-reveal",attrs:{"disabled":_vm.socketSendLoading !== null || _vm.towersGame === null || _vm.towersGame.state === 'completed' || _vm.towersGame.revealed.length !== _vm.row},on:{"click":function($event){return _vm.towersRevealButton(tile - 1)}}},[_c('div',{staticClass:"button-inner"},[_c('div',{staticClass:"inner-value"},[_c('span',[_vm._v(_vm._s(_vm.towersFormatValue(_vm.towersGetRowCashout).split('.')[0]))]),_vm._v("."+_vm._s(_vm.towersFormatValue(_vm.towersGetRowCashout).split('.')[1])+" ")])])])])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }